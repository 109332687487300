import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";

const PPIs = () => {
	return (
		<StaticQuery
			query={graphql`
				query {
					img: file(relativePath: { eq: "understanding-ppis-and-dementia.jpg" }) {
						childImageSharp {
							fluid(maxHeight: 400) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
				}
			`}
			render={({ img }) => (
				<Blog
					title="PPI’s and Dementia"
					img={<Img fluid={img.childImageSharp.fluid} alt="PPI's and Dementia" />}
					imgWidth={img}
				>
					<SEO
						title="PPI’s and Dementia"
						type="article"
						keywords={[
							`PPI's`,
							`Proton pump inhibitors`,
							`Dementia`,
							`Gastroenterologist`,
							`Dr Frank Brennan`,
							`Dr Brennan`,
							`Dr Spiro Raftopoulos`,
							`Dr Ian Yusoff`,
							`Dr Dev Segarajasingam`,
							`Dr Sam Rao`,
							`Dr Claire Gordon`,
							`Dr Duy Pham`,
						]}
						description="The relationship between dementia and proton pump inhibitors (PPIs)"
					/>
					<Par text="At least 2 studies have found a significant association between use of proton pump inhibitors (PPI’s) and incident dementia; further studies are required to determine if the relationship is causal or conincidental. The most recent prospective German study followed over73,000 subjects from 2004 to 2011 and evaluated the association between PPI use and incident dementia. The subjects were free of dementia at baseline and were 75 years of age or older. After adjusting for potential confounding variables eg age, gender, depression, stroke, heart disease and polypharmacy, patients receiving PPI therapy had a significantly increased risk of incident dementia (hazard ratio 1.4) compared to patients not receiving PPI therapy." />
					<Par text="Limited preclinical data showing an interaction between PPI’s and both amyloid and tau provide potential biological plausibility. Malabsorption of vitamin B12 or other nutrients due to long term PPI use could also play a role. It is also possible the association may reflect residual confounding by factors that lead to both the development of reflux disease with use of PPI’s and the development of dementia." />
					<Par text="Other drugs including anticholinergics ( eg tricyclics, first generation antihistamines and bladder antimuscarinics) and benzodiazepines have also been associated with a higher risk for developing dementia. Not all these studies have been replicated." />
					<Par text="These studies emphasise the importance (if emphasis was necessary) that all physicians need to weigh the benefits of PPI use (and other drugs) against the possible side effect of developing dementia. It should be remembered that there are a large number of individual risk factors associated with dementia; cerebrovascular disease, hypertension, peripheral atherosclerosis, type 2 diabetes and obesity. Also physically active individuals have a lower incidence and prevalence of cognitive decline and dementia. If PPI therapy is considered necessary then there may be other lifestyle measures (increased exercise and weight loss) a patient can undertake to reduce their risk. Active management of the other risk factors may also help." />
					<p>
						Association of Proton Pump Inhibitors With Risk of Dementia: A Pharmacoepidemiological Claims Data Analysis.
						JAMA Neurol 2016 Feb 15 (EPub Ahead of Print)
					</p>

					<p>
						Cumulative use of strong anticholinergics and incident dementia; a prospective cohort study. JAMA Intern Med
						2015:175:401
					</p>

					<p>Benzodiazepine use and risk of Alzheimer’s disease; case control study. BMJ 2014;349:g5205</p>
				</Blog>
			)}
		/>
	);
};

export default PPIs;
